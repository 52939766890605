import React, { useEffect, useState } from 'react';
import _fetch from '../config/api';
import { base_url } from '../config/config';
import TreeNode from './TreeNode';

const Tree = () => {
    const [genelogy, setGenelogy] = useState([]);
    const [genelogyMember, setGenelogyMember] = useState([]);

    useEffect(() => {
        fetchData();
        // fetchTree();
    }, []);

    const fetchData = async () => {
        try {
            const request = await _fetch(`${base_url}api/genelogy`, 'GET', {}, {});
            if (request.status === true) {
                setGenelogy(request?.response.directs || []);
            }
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };

    // const fetchTree = async () => {
    //     try {
    //         const request = await _fetch(`${base_url}api/genelogy-users/740070`, 'GET', {}, {});
    //         if (request.status === true) {
    //             setGenelogyMember(request?.response.directs || []);
    //         }
    //     } catch (error) {
    //         console.log('Error fetching data:', error);
    //     }
    // };

    return (
        <ul id="myUL">
            <TreeNode data={genelogy}>
                <TreeNode data={genelogyMember}>
                    {/* Add nested TreeNode components as needed */}
                </TreeNode>
            </TreeNode>
        </ul>
    );
};

export default Tree;

// import React, { useEffect, useState } from 'react';
// import _fetch from '../config/api';
// import { base_url } from '../config/config';
// import TreeNode from './TreeNode'; // Import TreeNode component

// const Tree = () => {
//     const [genelogy, setGenelogy] = useState([]);
//     const [genelogyMember, setGenelogyMember] = useState([]);

//     useEffect(() => {
//         fetchData();
//         fetchTree();
//     }, []);

//     const fetchData = async () => {
//         try {
//             const request = await _fetch(`${base_url}api/genelogy`, 'GET', {}, {});
//             if (request.status === true) {
//                 setGenelogy(request?.response.directs || []);
//             }
//         } catch (error) {
//             console.log('Error fetching data:', error);
//         }
//     };

//     const fetchTree = async () => {
//         try {
//             const request = await _fetch(`${base_url}api/genelogy-users`, 'GET', {}, {});
//             if (request.status === true) {
//                 setGenelogyMember(request?.response.directs || []);
//             }
//         } catch (error) {
//             console.log('Error fetching data:', error);
//         }
//     };

//     return (
//         <ul id="myUL">
//             <TreeNode data={genelogy}>
//                 <TreeNode data={genelogyMember}>

//                 </TreeNode>
//             </TreeNode>
//         </ul>
//     );
// };

// export default Tree;
