import React, { useEffect, useState } from 'react'
import _fetch from '../config/api'
import { base_url } from '../config/config'

const TokenDeposit = () => {

    const [depositHistory, setDepositHistory] = useState([]);

    useEffect(() => {
        fetchData();
    }, [])


    const fetchData = async () => {
        try {
            const request = await _fetch(`${base_url}api/deposithistory/newcvt`, 'GET', {}, {})
            console.log("staking_income", request)
            setDepositHistory(request.token_deposit_report)

        } catch (error) {
            console.log("Error fetching data:", error);
        }
    }

    // console.log("token", depositHistory);


    return (
        <>
            <div className="onew p-3">
                <div className="heading">
                    <h5>Deposit History</h5>
                </div>
                <div className="market-data">
                    <div className="table-responsive mt-3">
                        <table class="table m-0">
                            <thead className='table-heading'>
                                <tr>
                                    <th scope="col">S.No.</th>
                                    <th scope="col">Hash</th>
                                    <th scope="col">From</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Date & Time</th>
                                </tr>
                            </thead>
                            <tbody className='table-color'>
                                {depositHistory && depositHistory.map((item, index) => (
                                    <tr key={index} className={index % 2 === 0 ? 'green-bg' : 'yellow-bg'}>
                                        <td>{index + 1}</td>
                                        <td>{item.Hash.slice(0, 8)}</td>
                                        <td>{item.From.slice(0, 8)}</td>
                                        <td>{item.Amount}</td>
                                        <td>{item.Date}</td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                        <div className="pagination-custom">
                                    <nav aria-label="Pagination fgh">
                                        <ul className="pagination">
                                            <li className="page-item previous">
                                                <a className="page-link" href="#" aria-label="Previous">
                                                    <span aria-hidden="true">&larr;</span> Previous
                                                </a>
                                            </li>
                                            <div className="page-numbers">
                                                <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                                <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                <li className="page-item"><a className="page-link" href="#">...</a></li>
                                                <li className="page-item"><a className="page-link" href="#">8</a></li>
                                                <li className="page-item"><a className="page-link" href="#">9</a></li>
                                                <li className="page-item"><a className="page-link" href="#">10</a></li>
                                            </div>
                                            <li className="page-item next">
                                                <a className="page-link" href="#" aria-label="Next">
                                                    Next <span aria-hidden="true">&rarr;</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TokenDeposit

