import React, { useState } from 'react'
import logoPic from '../assets/images/logo.png'
import { NavLink, useNavigate } from 'react-router-dom'
import { base_url } from '../config/config'
import { toast } from 'react-toastify';
import Modal from './Modals';
import Modals from './Modals';
import icon_1 from "../assets/icons/icon_1.png"
import icon_2 from "../assets/icons/icon_2.png"
import icon_3 from "../assets/icons/icon_3.png"
import group from "../assets/icons/arrow_right_side.png"
const Login = () => {
    const [user_id, setUser_id] = useState();
    const [password, setPassword] = useState();
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        if (!user_id || !password) {
            toast.dismiss();
            toast.error('Please Enter the details');
            return;
        }
        try {
            const requestData = {
                user_id,
                password,
            };

            let response = await fetch(`${base_url}api/login`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            })

            const data = await response.json();
            console.log("data", data);

            if (data.status === true) {
                localStorage.setItem("token", data.access_token);
                localStorage.setItem("user_id", data.user_id);
                // <Modals />;
                navigate('/');
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            console.error('Error with email login:', error);
        }
    }

    return (
        <>
            <div className="page">
                <div className="container">
                    <div className="row p-lg-4 py-5 justify-content-between g-2">
                        <div className="col-lg-6 col-md-4 d-none d-md-block">
                            <div className="first_account">
                                <div className="logo">
                                    <img src={logoPic} alt='cv_token' />
                                </div>
                                <div className="details">
                                    <h5>Login to your account🌟</h5>
                                    <p>Welcome to CV Token. Login to your account now.</p>
                                </div>
                                <div className="icons mt-5"><img src={icon_1} alt='icon_1' /></div>
                                <div className="text">
                                    <h6>Decentralized Blockchain Asset</h6>
                                    <p>CV Token (CVT) is a decentralized digital asset on the Binance
                                        Smart Chain,offering secure and transparent transactions.</p>
                                </div>
                                <div className="icons mt-3"><img src={icon_2} alt='icon_1' /></div>
                                <div className="text">
                                    <h6>Metaverse Commerce</h6>
                                    <p>Use CV Tokens to buy and sell virtual products and services in the Metaverse,enhancing your virtual reality experience.
                                    </p>
                                </div>
                                <div className="icons mt-3"><img src={icon_3} alt='icon_1' /> </div>
                                <div className="text">
                                    <h6>Market Exploration & Security</h6>
                                    <p>The platform provides real-time market data,diverse trading options,and prioritizes top-tier security for users.</p>
                                </div>
                            </div>
                            {/* <div className="policy-content d-flex justify-content-space-between gap-5">
                                <span>Privacy Policy</span>
                                <span>Terms & Conditions</span>
                            </div> */}
                        </div>
                        <div className="col-lg-6 col-md-8 col-sm-12">
                            <form className="login-form">
                                <div className="mb-3 bg">
                                    <div className='arrow_icon'>
                                        <img src={group} alt='arrow-icon' />
                                        {/* <i class="bi bi-arrow-left-circle"></i> */}
                                    </div>
                                    <div className="login-email mt-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">User ID</label>
                                        <input type="email" className="input_style form-control" autoComplete='off' placeholder="Enter User ID" value={user_id} onChange={(e) => setUser_id(e.target.value)} />
                                    </div>
                                    <div className="login-password mt-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Password</label>
                                        <input type="password" className="input_style form-control" autoComplete='off' placeholder="Enter Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                    <div className="form-check">
                                        <div className="d-flex justify-content-between gap-5 ">
                                            <div className="">
                                                <input className="form-check-input input-checkbox" type="checkbox" id="gridCheck" />
                                                <label className="form-check-label" htmlFor="gridCheck">Remember Me</label>
                                            </div>
                                            <div className="">
                                                <input className="form-check-input input-checkbox" type="checkbox" id="gridCheck" />
                                                <label className="form-check-label" htmlFor="gridCheck">Terms & Conditions</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3 button">
                                        <button type="submit" className="login-button" onClick={handleLogin}>Login</button>
                                    </div>
                                    <div className="mb-3 forgot-pass">
                                        <NavLink to='/forgot' className='forgot-link'><span>Forgot Password?</span></NavLink>
                                    </div>
                                </div>
                            </form>
                            <div className="now mt-5 d-flex justify-content-center">
                                <p className='m-0'>Don't have an account ? <NavLink to='/signup' className='link'>
                                    <span>Create Now</span></NavLink></p>
                                {/* <Modals /> */}
                            </div>
                            <div className="last-div"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login