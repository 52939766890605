// import React, { useState } from 'react';
// import logo from "../assets/images/logo.png";
// import dashboard from "../assets/icons/dashboard_icon.png";
// import fund from "../assets/icons/fund_icon.png";
// import staking from "../assets/icons/staking_icon.png";
// import bonus from "../assets/icons/bonus_icon.png";
// import community from "../assets/icons/community_icon.png";
// import withdrawal from "../assets/icons/withdrawal_icon.png";
// import fund_transfer from "../assets/icons/fund transfer_icon.png";
// import help from "../assets/icons/help_icon.png";
// import facebook from "../assets/icons/facebook_icon.png";
// import whatsapp from "../assets/icons/whatsapp_icon.png";
// import { NavLink } from 'react-router-dom';


// const Sidebar = () => {

//     const [openSection, setOpenSection] = useState(null);
//     const [isSidebarOpen, setIsSidebarOpen] = useState(false);

//     const handleSidebarToggle = () => {
//         setIsSidebarOpen(!isSidebarOpen);
//     };

//     const closeSidebarOnItemClick = () => {
//         setIsSidebarOpen(false);
//     };

//     const handleSectionToggle = (section) => {
//         setOpenSection(openSection === section ? null : section);
//     };

//     return (
//         <>

//             <div className="sidebar min-vh-100 d-flex justify-content-between flex-column">
//                 <div className="m-1">
//                     <div className="logo-img ms-3 m-4 mb-3">
//                         <img src={logo} alt='cv token' />
//                     </div>
//                     <ul className='nav nav-pills flex-column mt-sm-0'>
//                         <li className='nav-item text-white fs-4 my-1 py-2 py-sm-0'>
//                             <NavLink to="/" className='link'>
//                                 <a href='#' className='nav-link  text-white fs-5' aria-current='page'>
//                                     <img src={dashboard} alt='dashboard_icon' />
//                                     <span className='ms-3'>Dashboard</span>
//                                 </a>
//                             </NavLink>
//                         </li>
//                         <li className='nav-item text-white fs-4 my-1 py-2 py-sm-0'>
//                             <a href='#' className='nav-link fs-5' aria-current='page' onClick={() => handleSectionToggle('fundManagement')}>
//                                 <img src={fund} alt='fund_icon' />
//                                 <span className='ms-3'>Fund Management</span>

//                                 <ul className={`nav-link-style mt-2  list-unstyled ${openSection === 'fundManagement' ? 'show' : 'collapse'}`}>

//                                     <li className="nav-item-submenu">
//                                         <NavLink to='/deposit_bep' className='nav-link-style'><span>Deposit BEP 20</span></NavLink>
//                                     </li>

//                                     <li className="nav-item-submenu">
//                                         <NavLink to='/deposit_history' className='nav-link-style'><span>Deposit History</span></NavLink>
//                                     </li>

//                                     <li className="nav-item-submenu">
//                                         <NavLink to='/wallet_ledger' className='nav-link-style'><span>Wallet Ledger</span></NavLink>
//                                     </li>
//                                 </ul>
//                             </a>
//                         </li>
//                         <li className='nav-item text-white fs-4 my-1 py-2 py-sm-0'>
//                             <a href='#' className='nav-link  fs-5' aria-current='page' onClick={() => handleSectionToggle('staking')}>
//                                 <img src={staking} alt='staking_icon' />
//                                 <span className='ms-3'>Staking</span>

//                                 <ul className={`nav-link-style  list-unstyled ${openSection === 'staking' ? 'show' : 'collapse'}`}>
//                                     <li className="nav-item-submenu">
//                                         <NavLink to='/stake_token' className='nav-link-style'><span>Stake Tokens</span></NavLink>
//                                     </li>
//                                     <li className="nav-item-submenu">
//                                         <NavLink to='/stake_token_history' className='nav-link-style'><span>Stake Token History</span></NavLink>
//                                     </li>

//                                 </ul>
//                             </a>
//                         </li>
//                         <li className='nav-item text-white fs-4 my-1 py-2 py-sm-0'>
//                             <a href='#' className='nav-link fs-5' aria-current='page' onClick={() => handleSectionToggle('Bonus Portfolio')}>
//                                 <img src={bonus} alt='bonus portfolio_icon' />
//                                 <span className='ms-3'>Bonus Portfolio</span>

//                                 <ul className={`nav-link-style  list-unstyled ${openSection === 'Bonus Portfolio' ? 'show' : 'collapse'}`}>
//                                     <li className="nav-item-submenu">
//                                         <NavLink to='/daily_staking_income' className='nav-link-style'><span>Daily Stacking Income</span></NavLink>
//                                     </li>
//                                     <li className="nav-item-submenu">
//                                         <NavLink to='/direct_income' className='nav-link-style'><span>Direct Income</span></NavLink>
//                                     </li>
//                                     <li className="nav-item-submenu">
//                                         <NavLink to='/matrix_income' className='nav-link-style'><span>Matrix Income</span></NavLink>
//                                     </li>
//                                     <li className="nav-item-submenu">
//                                         <NavLink to='/rewards_income' className='nav-link-style'><span>Rewards Income</span></NavLink>
//                                     </li>
//                                     <li className="nav-item-submenu">
//                                         <NavLink to='/income_ledger' className='nav-link-style'><span>Income Ledger</span></NavLink>
//                                     </li>
//                                     <li className="nav-item-submenu">
//                                         <NavLink to='/starclub_bonus' className='nav-link-style'><span>Starclub Bonus</span></NavLink>
//                                     </li>
//                                     <li className="nav-item-submenu">
//                                         <NavLink to='/leadership_bonus' className='nav-link-style'><span>Leadership Bonus</span></NavLink>
//                                     </li>
//                                 </ul>
//                             </a>
//                         </li>
//                         <li className='nav-item text-white fs-4 my-1 py-2 py-sm-0'>
//                             <a href='#' className='nav-link fs-5' aria-current='page' onClick={() => handleSectionToggle('Community')} >
//                                 <img src={community} alt='community_icon' />
//                                 <span className='ms-3'>Community</span>

//                                 <ul className={`nav-link-style  list-unstyled ${openSection === 'Community' ? 'show' : 'collapse'}`}>
//                                     <li className="nav-item-submenu">
//                                         <NavLink to='/direct_referral' className='nav-link-style'><span>Direct Referrals</span></NavLink>
//                                     </li>
//                                     <li className="nav-item-submenu">
//                                         <NavLink to='' className='nav-link-style'><span>Tree</span></NavLink>
//                                     </li>
//                                     <li className="nav-item-submenu">
//                                         <NavLink to='' className='nav-link-style'><span>Genelogy</span></NavLink>
//                                     </li>
//                                     <li className="nav-item-submenu">
//                                         <NavLink to='/matrix_downline' className='nav-link-style'><span>Matrix Downline List</span></NavLink>
//                                     </li>
//                                     <li className="nav-item-submenu">
//                                         <NavLink to='/self_downline' className='nav-link-style'><span>Self Downline List</span></NavLink>
//                                     </li>
//                                 </ul>
//                             </a>
//                         </li>
//                         <li className='nav-item text-white fs-4 my-1 py-2 py-sm-0'>
//                             <a href='#' className='nav-link fs-5' aria-current='page' onClick={() => handleSectionToggle('Withdrawal')}>
//                                 <span><img src={withdrawal} alt='withdrawal_icon' /></span>
//                                 <span className='ms-3'>Withdrawal</span>

//                                 <ul className={`nav-link-style   list-unstyled ${openSection === 'Withdrawal' ? 'show' : 'collapse'}`}>
//                                     <li className="nav-item-submenu">
//                                         <NavLink to='/cvt_withdrawal' className='nav-link-style'><span>CVT Withdrawals</span></NavLink>
//                                     </li>
//                                     <li className="nav-item-submenu">
//                                         <NavLink to='/cvt_withdrawals_history' className='nav-link-style'><span>CVT Withdrawals History</span></NavLink>
//                                     </li>
//                                     <li className="nav-item-submenu">
//                                         <NavLink to='/usdt_withdrawal' className='nav-link-style'><span>USDT Withdrawals</span></NavLink>
//                                     </li>
//                                     <li className="nav-item-submenu">
//                                         <NavLink to='/usdt_withdrawals_history' className='nav-link-style'><span>USDT Withdrawals History</span></NavLink>
//                                     </li>
//                                 </ul>
//                             </a>
//                         </li>
//                         <li className='nav-item text-white fs-4 my-1 py-2 py-sm-0'>
//                             <NavLink to='/fund_transfer' className='nav-link fs-5' aria-current='page' >
//                                 <img src={fund_transfer} alt='fund transfer_icon' />
//                                 <span className='ms-3'>Fund Transfer</span>
//                             </NavLink>
//                         </li>
//                         <li className='nav-item text-white fs-4 my-1 py-2 py-sm-0'>
//                             <a href='#' className='nav-link  fs-5' aria-current='page' onClick={() => handleSectionToggle('help')}>
//                                 <img src={help} alt='help desk_icon' />
//                                 <span className='ms-3'>Help Desk</span>
//                                 <ul className={`nav-link-style   list-unstyled ${openSection === 'help' ? 'show' : 'collapse'}`}>
//                                     <li className="nav-item-submenu">
//                                         <NavLink to='/create_ticket' className='nav-link-style'><span>Create Ticket</span></NavLink>
//                                     </li>
//                                     <li className="nav-item-submenu">
//                                         <NavLink to='/ticket_list' className='nav-link-style'><span>Ticket List</span></NavLink>
//                                     </li>
//                                 </ul>
//                             </a>
//                         </li>
//                     </ul>
//                 </div>

//                 <div className="box m-1">
//                     <div className="box1">
//                         <div className="box2">
//                             <span>Referral Link</span>
//                         </div>
//                         <div className="d-flex gap-3 p-2 mb-2">
//                             <img src={whatsapp} alt='whatsapp' />
//                             <img src={facebook} alt='facebook' />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>

//     );
// };

// export default Sidebar;


import React, { useState } from 'react';
import logo from "../assets/images/logo.png";
import dashboard from "../assets/icons/dashboard_icon.png";
import fund from "../assets/icons/fund_icon.png";
import staking from "../assets/icons/staking_icon.png";
import bonus from "../assets/icons/bonus_icon.png";
import community from "../assets/icons/community_icon.png";
import withdrawal from "../assets/icons/withdrawal_icon.png";
import fund_transfer from "../assets/icons/fund transfer_icon.png";
import help from "../assets/icons/help_icon.png";
import facebook from "../assets/icons/facebook_icon.png";
import whatsapp from "../assets/icons/whatsapp_icon.png";
import { NavLink } from 'react-router-dom';

const Sidebar = ({ isSidebarOpen }) => {
    const [openSection, setOpenSection] = useState(null);

    const handleSectionToggle = (section) => {
        setOpenSection(openSection === section ? null : section);
    };

    return (
        <>
            {/* <div className="sidebarViewport_aRkj">
                <div className="sidebar_njMd">
                    <nav aria-label='Docs sidebar' className='menu thin-scrollbar menu_SIkG'>
                        <ul className='theme-doc-sidebar-menu menu__list'>

                        </ul>

                    </nav>
                </div>
            </div> */}
            <div className="sidebarViewport_aRkj">
                <div className={`sidebar  min-vh-100 d-flex justify-content-between flex-column ${isSidebarOpen ? 'open' : 'closed'}`}>
                    <nav aria-label="Docs sidebar" className='menu thin-scrollbar menu_SIkG m-2'>
                        <div className="app m-1">
                            <div className="logo-img ms-3 m-4 mb-3">
                                <img src={logo} alt='cv token' />
                            </div>
                            <ul className='nav nav-pills flex-column mt-sm-0'>
                                <li className='nav-item text-white fs-4 my-1 py-2 py-sm-0'>
                                    <NavLink to="/" className='link'>
                                        <span className='nav-link text-white fs-5' aria-current='page'>
                                            <img src={dashboard} alt='dashboard_icon' />
                                            <span className='ms-3'>Dashboard</span>
                                        </span>
                                    </NavLink>
                                </li>
                                <li className='nav-item text-white fs-4 my-1 py-2 py-sm-0'>
                                    <a href='#' className='nav-link fs-5' aria-current='page' onClick={() => handleSectionToggle('fundManagement')}>
                                        <img src={fund} alt='fund_icon' />
                                        <span className='ms-3'>Fund Management</span>
                                        <ul className={`nav-link-style mt-2 list-unstyled ${openSection === 'fundManagement' ? 'show' : 'collapse'}`}>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/deposit_bep' className='nav-link-style'> <span>Deposit BEP 20</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/deposit_history' className='nav-link-style'> <span>Deposit History</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/wallet_ledger' className='nav-link-style' ><span>Wallet Ledger</span></NavLink>
                                            </li>
                                        </ul>
                                    </a>
                                </li>
                                <li className='nav-item text-white fs-4 my-1 py-2 py-sm-0'>
                                    <a href='#' className='nav-link fs-5' aria-current='page' onClick={() => handleSectionToggle('staking')}>
                                        <img src={staking} alt='staking_icon' />
                                        <span className='ms-3'>Staking</span>
                                        <ul className={`nav-link-style list-unstyled ${openSection === 'staking' ? 'show' : 'collapse'}`}>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/stake_token' className='nav-link-style'><span>Stake Tokens</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/stake_token_history' className='nav-link-style'><span>Stake Token History</span></NavLink>
                                            </li>
                                        </ul>
                                    </a>
                                </li>
                                <li className='nav-item text-white fs-4 my-1 py-2 py-sm-0'>
                                    <a href='#' className='nav-link fs-5' aria-current='page' onClick={() => handleSectionToggle('Bonus Portfolio')}>
                                        <img src={bonus} alt='bonus portfolio_icon' />
                                        <span className='ms-3'>Bonus Portfolio</span>
                                        <ul className={`nav-link-style list-unstyled ${openSection === 'Bonus Portfolio' ? 'show' : 'collapse'}`}>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/daily_staking_income' className='nav-link-style' ><span>Daily Staking Income</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/direct_income' className='nav-link-style' ><span>Direct Income</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/matrix_income' className='nav-link-style' ><span>Matrix Income</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/rewards_income' className='nav-link-style' ><span>Rewards Income</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/income_ledger' className='nav-link-style' ><span>Income Ledger</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/starclub_bonus' className='nav-link-style' ><span>Starclub Bonus</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/leadership_bonus' className='nav-link-style' ><span>Leadership Bonus</span></NavLink>
                                            </li>
                                        </ul>
                                    </a>
                                </li>
                                <li className='nav-item text-white fs-4 my-1 py-2 py-sm-0'>
                                    <a href='#' className='nav-link fs-5' aria-current='page' onClick={() => handleSectionToggle('Community')}>
                                        <img src={community} alt='community_icon' />
                                        <span className='ms-3'>Community</span>
                                        <ul className={`nav-link-style list-unstyled ${openSection === 'Community' ? 'show' : 'collapse'}`}>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/direct_referral' className='nav-link-style' ><span>Direct Referrals</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/tree_chart' className='nav-link-style' ><span>Tree</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/genelogy' className='nav-link-style' ><span>Genelogy</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/matrix_downline' className='nav-link-style' ><span>Matrix Downline List</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/self_downline' className='nav-link-style' ><span>Self Downline List</span></NavLink>
                                            </li>
                                        </ul>
                                    </a>
                                </li>
                                <li className='nav-item text-white fs-4 my-1 py-2 py-sm-0'>
                                    <a href='#' className='nav-link fs-5' aria-current='page' onClick={() => handleSectionToggle('Withdrawal')}>
                                        <img src={withdrawal} alt='withdrawal_icon' />
                                        <span className='ms-3'>Withdrawal</span>
                                        <ul className={`nav-link-style   list-unstyled ${openSection === 'Withdrawal' ? 'show' : 'collapse'}`}>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/cvt_withdrawal' className='nav-link-style'><span>CVT Withdrawals</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/cvt_withdrawals_history' className='nav-link-style'><span>CVT Withdrawals History</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/usdt_withdrawal' className='nav-link-style'><span>USDT Withdrawals</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/usdt_withdrawals_history' className='nav-link-style'><span>USDT Withdrawals History</span></NavLink>
                                            </li>
                                        </ul>
                                    </a>
                                </li>
                                <li className='nav-item text-white fs-4 my-1 py-2 py-sm-0'>
                                    <NavLink to='/fund_transfer' className='nav-link fs-5' aria-current='page' >
                                        <img src={fund_transfer} alt='fund transfer_icon' />
                                        <span className='ms-3'>Fund Transfer</span>
                                    </NavLink>
                                </li>
                                <li className='nav-item text-white fs-4 my-1 py-2 py-sm-0'>
                                    <a href='#' className='nav-link  fs-5' aria-current='page' onClick={() => handleSectionToggle('help')}>
                                        <img src={help} alt='help desk_icon' />
                                        <span className='ms-3'>Help Desk</span>
                                        <ul className={`nav-link-style   list-unstyled ${openSection === 'help' ? 'show' : 'collapse'}`}>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/create_ticket' className='nav-link-style'><span>Create Ticket</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/ticket_list' className='nav-link-style'><span>Ticket List</span></NavLink>
                                            </li>
                                        </ul>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>

                    <div className="box m-1">
                        <div className="box1">
                            <div className="box2">
                                <span>Referral Link</span>
                            </div>
                            <div className="d-flex gap-3 p-2 mb-2">
                                <img src={whatsapp} alt='whatsapp' />
                                <img src={facebook} alt='facebook' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sidebar;

