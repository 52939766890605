import React, { useEffect, useState } from 'react'
import _fetch from '../config/api'
import { base_url } from '../config/config'

const WalletLedger = () => {

    const [walletLedger, setWalletLedger] = useState([]);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        try {
            const request = await _fetch(`${base_url}api/transfer-fund`, 'GET', {}, {})
            // console.log("wallet", request)
            setWalletLedger(request.data)

        } catch (error) {
            console.log("Error fetching data:", error);
        }
    }

    // console.log("wallet", walletLedger);

    return (
        <>
            <div className="onew p-3">
                <div className="heading">
                    <h5>Wallet Ledger</h5>
                </div>
                <div className="rounded">
                    <div className="table-responsive mt-3">
                        <table class="table">
                            <thead className='table-heading'>
                                <tr>
                                    <th scope="col">S.No.</th>
                                    <th scope="col">User ID</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Sender ID</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Remarks</th>
                                    <th scope="col">Created On</th>
                                </tr>
                            </thead>
                            <tbody className='table-color'>
                                {walletLedger && walletLedger.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.user_id}</td>
                                        <td style={{ color: item.amount < 0 ? 'red' : 'green' }}>
                                            {item.amount}
                                        </td>
                                        <td>{item.sender_id}</td>
                                        <td>{item.type}</td>
                                        <td>{item.remark}</td>
                                        <td>{item.created_at}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="pagination-custom">
                                    <nav aria-label="Pagination fgh">
                                        <ul className="pagination">
                                            <li className="page-item previous">
                                                <a className="page-link" href="#" aria-label="Previous">
                                                    <span aria-hidden="true">&larr;</span> Previous
                                                </a>
                                            </li>
                                            <div className="page-numbers">
                                                <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                                <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                <li className="page-item"><a className="page-link" href="#">...</a></li>
                                                <li className="page-item"><a className="page-link" href="#">8</a></li>
                                                <li className="page-item"><a className="page-link" href="#">9</a></li>
                                                <li className="page-item"><a className="page-link" href="#">10</a></li>
                                            </div>
                                            <li className="page-item next">
                                                <a className="page-link" href="#" aria-label="Next">
                                                    Next <span aria-hidden="true">&rarr;</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default WalletLedger