import React, { useEffect, useState } from 'react'
import _fetch from '../config/api'
import { base_url } from '../config/config'
import { toast } from 'react-toastify';

const Epsfounder = () => {

    const [transaction, setTransaction] = useState([]);


    const fetchProfile = async () => {
        try {
            const request = await _fetch(`${base_url}api/epsfounder`, 'GET', {}, {});
            // console.log('request------>', );
            if (request.status === true) {
                setTransaction(request.data)
            } else {
                toast.error(request.message)
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }


    useEffect(() => {
        fetchProfile();
    }, []);


    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="onew p-3">
                        <div className="heading_name">
                            <h3>EPS Report</h3>
                        </div>
                        <div className="rounded">
                            <div className="table-responsive mt-3">
                                <table class="table">
                                    <thead className='table-heading'>
                                        <tr>
                                            <th scope="col">Sr.No</th>
                                            <th scope="col">User Id</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody className='table-color'>
                                        {transaction && transaction.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.user_id}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.status}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                                <div className="pagination-custom">
                                    <nav aria-label="Pagination fgh">
                                        <ul className="pagination">
                                            <li className="page-item previous">
                                                <a className="page-link" href="#" aria-label="Previous">
                                                    <span aria-hidden="true">&larr;</span> Previous
                                                </a>
                                            </li>
                                            <div className="page-numbers">
                                                <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                                <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                <li className="page-item"><a className="page-link" href="#">...</a></li>
                                                <li className="page-item"><a className="page-link" href="#">8</a></li>
                                                <li className="page-item"><a className="page-link" href="#">9</a></li>
                                                <li className="page-item"><a className="page-link" href="#">10</a></li>
                                            </div>
                                            <li className="page-item next">
                                                <a className="page-link" href="#" aria-label="Next">
                                                    Next <span aria-hidden="true">&rarr;</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Epsfounder