import React, { useState } from 'react'
import { base_url } from '../config/config'

const StakeToken = () => {

    const [amount, setAmount] = useState();

    
    const handleStake = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem("token");
        if (!token) {
            console.error("Token not found");
            return;
        }

        console.log("Using token:", token);

        try {
            const requestData = {
                amount,
                package_id: "400"
            };

            let response = await fetch(`${base_url}api/stake_tokens`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": token,
                },
                body: JSON.stringify(requestData),
            });

            const data = await response.json();
            // console.log("Response data:", data);

        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
        <>
            <div className="col-lg-6 col-md-10 col-sm-12">
                <div className="heading p-1 mb-3 mt-1">
                    <h4>Staking Plan</h4>
                </div>
                <form className="login-form">
                    <div className="mb-3 bg_2">
                        <div className="heading_inner p-1 mb-3 mt-4">
                            <h4>Staking Plan</h4>
                        </div>
                        <div className="login-data mt-2">
                            <div className='headlight-data m-3'><span className='headlight-data-span'>We are offering you Tokens in the Preesale Runod Price we wish you a bright future</span></div>
                        </div>
                        <div className='wallet mt-5'>
                            <span>Wallet Balance</span>
                        </div>
                        <div className="balance mt-2 mb-4">
                            <span>0.0000000000000</span>
                        </div>
                        <div className="login-amount mt-3">
                            <input type="amount" className="input_style form-control" autoComplete='off' placeholder="Enter Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                        </div>
                        <div className="mb-3 button mt-5">
                            <button type="submit" className="login-button" onClick={handleStake}>Stake</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default StakeToken