import React, { useEffect, useState } from 'react'
import { base_url } from '../config/config';
import _fetch from '../config/api';
import toast from 'react-hot-toast';

const SelfDownline = () => {

    const [selfDownline, setSelfDownline] = useState([]);
    const userid = localStorage.getItem("user_id")


    useEffect(() => {
        fetchSelf()
    }, [])

    const fetchSelf = async () => {
        try {
            const request = await _fetch(`${base_url}api/self-level-view/${userid}`, 'GET', {}, {});
            console.log('request------>', request);

            setSelfDownline(request.data)

        } catch (error) {
            console.error("Error fetching data:", error);
        }

    }


    console.log('userid', userid);
    return (
        <>
            <div className="onew p-3">
                <div className="heading">
                    <h5>Self Team Level View</h5>
                </div>
                <div className="rounded">
                    <div className="table-responsive mt-3">

                        <table class="table">
                            <thead className='table-heading'>
                                <tr>
                                    <th scope="col">S.No.</th>
                                    <th scope="col">Level</th>
                                    <th scope="col">Total Team</th>
                                    <th scope="col">Team Business</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody className='table-color'>
                                {selfDownline && selfDownline.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.level}</td>
                                            <td>{item.total_team}</td>
                                            <td>{item.team_business}</td>
                                            <td>{item.action}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SelfDownline