import React, { useEffect, useState } from 'react'
import _fetch from '../config/api'
import { base_url } from '../config/config'
import { toast } from 'react-toastify';

const Founder = () => {

    const [transaction, setTransaction] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [per_page] = useState(10);
    const [totalPages, setTotalPages] = useState(0);

    const fetchProfile = async () => {
        try {
            const request = await _fetch(`${base_url}api/founder?page=${currentPage}&per_page=${per_page}`, 'GET', {}, {});

            if (request.status === true) {
                setTransaction(request.data)
                setTotalPages(parseInt(request.pagination.total_pages, 10));
            } else {
                toast.error(request.message)
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    console.log('request------>', transaction);

    useEffect(() => {
        fetchProfile();
    }, [currentPage, per_page]);

    const handlePageClick = (e, page) => {
        e.preventDefault();
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handlePreviousClick = (e) => {
        e.preventDefault();
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextClick = (e) => {
        e.preventDefault();
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const renderPageNumbers = () => {
        const pages = [];
        const maxPageNumbersToShow = 5;

        if (totalPages <= maxPageNumbersToShow) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            if (currentPage <= 3) {
                pages.push(1, 2, 3, 4, '...', totalPages);
            } else if (currentPage >= totalPages - 2) {
                pages.push(1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
            } else {
                pages.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
            }
        }
        return pages;
    };


    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="onew p-3">
                        <div className="heading_name">
                            <h3>Founder Report</h3>
                        </div>
                        <div className="rounded">
                            <div className="table-responsive mt-3">
                                <table class="table">
                                    <thead className='table-heading'>
                                        <tr>
                                            <th scope="col">Sr.No</th>
                                            <th scope="col">User Id</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Date</th>
                                        </tr>
                                    </thead>
                                    <tbody className='table-color'>
                                        {transaction && transaction.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{(currentPage - 1) * per_page + index + 1}</td>
                                                    <td>{item.user_id}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.status}</td>
                                                    <td>{item.date}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                                <div className="pagination-custom">
                                    <nav aria-label="Pagination fgh">
                                        <ul className="pagination">
                                            <li className={`page-item previous ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <a className="page-link" href="#" onClick={handlePreviousClick}>
                                                    <span className='arrow-pagination' aria-hidden="true"><i class="bi bi-arrow-left"></i></span> Previous
                                                </a>
                                            </li>
                                            <div className="page-numbers">
                                                {renderPageNumbers().map((page, index) => (
                                                    <li key={index} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                                        {page === '...' ? (
                                                            <span className="page-link">...</span>
                                                        ) : (
                                                            <a className="page-link" href="#" onClick={(e) => handlePageClick(e, page)}>
                                                                {page}
                                                            </a>
                                                        )}
                                                    </li>
                                                ))}
                                            </div>
                                            <li className={`page-item next ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                <a className="page-link" href="#" onClick={handleNextClick}>
                                                    Next <span aria-hidden="true">
                                                        <i className="bi bi-arrow-right"></i>
                                                    </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Founder