import React, { useState } from 'react'
import { base_url } from '../config/config';

const FundTransfer = () => {

    const [user_id, setUser_id] = useState("");
    const [amount, setAmount] = useState("");


    const handleToken = async (e) => {

        e.preventDefault();

        const token = localStorage.getItem("token");
        if (!token) {
            console.error("Token not found");
            return;
        }

        // console.log("token", token);

        try {
            const requestData = {
                user_id,
                amount,
            };

            let response = await fetch(`${base_url}api/token-transfer`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": token,
                },
                body: JSON.stringify(requestData),
            })

            const data = await response.json();
            console.log("data", data);

            setUser_id(""); // Reset title
            setAmount(""); // Reset message


        } catch (error) {
            console.error('Error with email login:', error);
        }
    }

    return (
        <>
            <div className="col-lg-6 col-md-10 col-sm-12">
                <div className="heading p-1 mb-3 mt-1">
                    <h4>Fund Transfer</h4>
                </div>
                <form className="login-form">
                    <div className="mb-3 transfer">
                        <div className="login-email mt-4">
                            <label htmlFor="exampleFormControlInput1" className="form-label">User ID</label>
                            <input type="text" className="input_style form-control" autoComplete='off' placeholder="Enter User ID" value={user_id} onChange={(e) => setUser_id(e.target.value)} />
                        </div>
                        <div className="login-password mt-4">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Amount</label>
                            <input type='number' className="textarea input_style form-control" autoComplete='off' placeholder="Enter Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                            <div className="mb-3 button mt-5">
                                <button type="submit" className="submit-button" onClick={handleToken}>Transfer</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default FundTransfer