import React, { useEffect, useState } from 'react'
import { base_url } from '../config/config';
import _fetch from '../config/api';
import toast from 'react-hot-toast';

const CvtWithdrawals_history = () => {

    const [historycvt, setHistoryCvt] = useState('');

    useEffect(() => {
        fetchHistory();
    }, [])


    const fetchHistory = async () => {
        try {
            const request = await _fetch(`${base_url}api/cvtwithdraw-history`, 'GET', {}, {});
            // console.log('request------>', request);

            setHistoryCvt(request?.transactionData)

            // console.log("history", historycvt);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }


    return (

        <>
            <div className="onew p-3">
                <div className="heading">
                    <h5>CVT Withdrawal History </h5>
                </div>
                <div className="rounded">
                    <div className="table-responsive mt-3">
                        <table class="table">
                            <thead className='table-heading'>
                                <tr>
                                    <th scope="col">S.No.</th>
                                    <th scope="col">User ID</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Deductions</th>
                                    <th scope="col">Payable Amount</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Date</th>
                                </tr>
                            </thead>
                            <tbody className='table-color'>
                                {historycvt && historycvt.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.user_id}</td>
                                            <td>{item.amount}</td>
                                            <td>{item.status}</td>
                                            <td>{item.deductions}</td>
                                            <td>{item.payable_amount}</td>
                                            <td>{item.description}</td>
                                            <td>{item.credit_date}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>

                        <div className="pagination-custom">
                            <nav aria-label="Pagination fgh">
                                <ul className="pagination">
                                    <li className="page-item previous">
                                        <a className="page-link" href="#" aria-label="Previous">
                                            <span aria-hidden="true">&larr;</span> Previous
                                        </a>
                                    </li>
                                    <div className="page-numbers">
                                        <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                        <li className="page-item"><a className="page-link" href="#">...</a></li>
                                        <li className="page-item"><a className="page-link" href="#">8</a></li>
                                        <li className="page-item"><a className="page-link" href="#">9</a></li>
                                        <li className="page-item"><a className="page-link" href="#">10</a></li>
                                    </div>
                                    <li className="page-item next">
                                        <a className="page-link" href="#" aria-label="Next">
                                            Next <span aria-hidden="true">&rarr;</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CvtWithdrawals_history