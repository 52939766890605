import React from 'react'

const LeadershipBonus = () => {
    return (
        <>
            <div className="onew p-3">
                <div className="heading">
                    <h5>Leader Ship Bonus</h5>
                </div>
                <div className="rounded">
                    <div className="table-responsive mt-3">

                        <table class="table">
                            <thead className='table-heading'>
                                <tr>
                                    <th scope="col">Matching</th>
                                    <th scope="col">Directs Business</th>
                                    <th scope="col">Rewards</th>
                                    <th scope="col">Bonus</th>
                                </tr>
                            </thead>
                            <tbody className='table-color'>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="pagination-custom">
                                    <nav aria-label="Pagination fgh">
                                        <ul className="pagination">
                                            <li className="page-item previous">
                                                <a className="page-link" href="#" aria-label="Previous">
                                                    <span aria-hidden="true">&larr;</span> Previous
                                                </a>
                                            </li>
                                            <div className="page-numbers">
                                                <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                                <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                <li className="page-item"><a className="page-link" href="#">...</a></li>
                                                <li className="page-item"><a className="page-link" href="#">8</a></li>
                                                <li className="page-item"><a className="page-link" href="#">9</a></li>
                                                <li className="page-item"><a className="page-link" href="#">10</a></li>
                                            </div>
                                            <li className="page-item next">
                                                <a className="page-link" href="#" aria-label="Next">
                                                    Next <span aria-hidden="true">&rarr;</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LeadershipBonus