import React, { useState } from 'react';

const TreeNode = ({ data }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <li>
            <span className={`caret ${isOpen ? 'caret-down' : ''}`} onClick={toggle}>
                {isOpen ? '[-]' : '[+]'}
            </span>
            <ul className={`nested ${isOpen ? 'active' : ''}`}>
                {data.map((item, index) => (
                    <div className="card_qw d-flex gap-2" key={index}>
                        <div className="profile-pic">
                            <img
                                alt="Profile picture"
                                height="50"
                                src="https://oaidalleapiprodscus.blob.core.windows.net/private/org-LmQ09WWGIGwOeeA4ArnRw0x5/user-uJPET5fjNenSso8wCETWVNOp/img-EHvQxLhdx5zZYROC7fUdwAH0.png?st=2024-09-12T06%3A05%3A59Z&amp;se=2024-09-12T08%3A05%3A59Z&amp;sp=r&amp;sv=2024-08-04&amp;sr=b&amp;rscd=inline&amp;rsct=image/png&amp;skoid=d505667d-d6c1-4a0a-bac7-5c84a87759f8&amp;sktid=a48cca56-e6da-484e-a814-9c849652bcb3&amp;skt=2024-09-11T23%3A48%3A15Z&amp;ske=2024-09-12T23%3A48%3A15Z&amp;sks=b&amp;skv=2024-08-04&amp;sig=eID9fdCk4OGszh/DBmp0ccZ7Lr7XggunkgWqcZHD8/E%3D"
                                width="50"
                            />
                        </div>
                        <div className="info">
                            <h2>{item.name}</h2>
                            <p>{item.all_directs} Members</p>
                        </div>
                        <div className="id-badge">
                            {item.sponser_id}
                        </div>
                        {/* Render nested TreeNodes if applicable */}
                        {item.children && item.children.length > 0 && (
                            <TreeNode data={item.children} />
                        )}
                    </div>
                ))}
            </ul>
        </li>
    );
};


export default TreeNode