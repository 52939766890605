import React, { useState } from 'react'
import logoPic from '../assets/images/logo.png'
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import icon_1 from "../assets/icons/icon_1.png"
import icon_2 from "../assets/icons/icon_2.png"
import icon_3 from "../assets/icons/icon_3.png"
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

const SignUp = () => {

    const [sponsor, setSponsor] = useState();
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [number, setNumber] = useState();
    const [phone, setPhone] = useState('');

    const handleSignup = (e) => {
        e.preventDefault();
    }

    return (
        <>
            <div className="page">
                <div className="container">
                    <div className="row p-lg-3 py-5 justify-content-between g-3">
                        <div className="col-lg-6 col-md-4 d-none d-md-block">
                            <div className="first_account">
                                <div className="logo">
                                    <img src={logoPic} alt='cv_token' />
                                </div>
                                <div className="details">
                                    <h5>Get Started with Us🌟</h5>
                                    <p>Welcome to CV Token.Login to your account now.</p>
                                </div>
                                <div className="icons mt-5"><img src={icon_1} alt='icon_1' /></div>
                                <div className="text">
                                    <h6>Decentralized Blockchain Asset</h6>
                                    <p>CV Token (CVT) is a decentralized digital asset on the Binance
                                        Smart Chain,offering secure and transparent transactions.</p>
                                </div>
                                <div className="icons mt-3"><img src={icon_2} alt='icon_2' /></div>
                                <div className="text">
                                    <h6>Metaverse Commerce</h6>
                                    <p>Use CV Tokens to buy and sell virtual products and services in the Metaverse,enhancing your virtual reality experience.
                                    </p>
                                </div>
                                <div className="icons mt-3"><img src={icon_3} alt='icon_3' /></div>
                                <div className="text">
                                    <h6>Market Exploration & Security</h6>
                                    <p>The platform provides real-time market data,diverse trading options,and prioritizes top-tier security for users.</p>
                                </div>
                            </div>
                            {/* <div className="policy-content-signup d-flex justify-content-space-between gap-5">
                                <span>Privacy Policy</span>
                                <span>Terms & Conditions</span>
                            </div> */}
                        </div>
                        <div className="col-lg-6 col-md-8 col-sm-12">
                            <form className="signup-form">
                                <div className="mb-3 signup">
                                    <div className="signup-sponsor mt-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Sponsor ID</label>
                                        <input type="text" className="input_style form-control" placeholder="Enter User ID" value={sponsor} onChange={(e) => setSponsor(e.target.value)} />
                                    </div>
                                    <div className="signup-Name mt-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Full Name</label>
                                        <input type="text" className="input_style form-control" placeholder="Enter Full Name" value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                    <div className="signup-email mt-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Email Address</label>
                                        <input type="email" className="input_style form-control" placeholder="Enter Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                    <div className="signup-PhoneNumber mt-3">
                                        <label htmlFor="exampleFormControlInput1" className="number-style">Phone Number</label>
                                        <div className="d-flex gap-3">
                                            {/* <PhoneInput
                                                defaultCountry="ua"
                                                value={phone}
                                                onChange={(phone) => setPhone(phone)}
                                            /> */}
                                            {/* <select className="input_style_phone form-control-lg mt-1" >
                                                <option hidden value="">Select</option>
                                            </select> */}
                                            {/* <input type="number" id="phone_number" className="input_style form-control mt-1" placeholder="Enter Number" value={number} onChange={(e) => setNumber(e.target.value)} /> */}
                                        </div>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input input-checkbox" type="checkbox" id="gridCheck" />
                                        <label className="form-check-label" htmlFor="gridCheck">I agree to the terms & conditions </label>
                                    </div>
                                    <div className="mb-3 button">
                                        <button type="submit" className="login-button" onClick={handleSignup}>Create Account</button>
                                    </div>
                                </div>
                            </form>
                            <div className="now mt-4 d-flex justify-content-center">
                                <p>Already have an account ? <NavLink to='/login' className='link'><span>Login</span></NavLink></p>
                            </div>
                            <div className="last-div"></div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SignUp