import React, { useState } from 'react'
import logoPic from '../assets/images/logo.png'
import { NavLink } from 'react-router-dom'

const Forgot = () => {

    const [email, setEmail] = useState();

    const handlePassword = (e) => {
        e.preventDefault();
    }


    return (
        <>
            <div className="page">
                <div className="first_account py-3">
                    <div className="logo px-5">
                        <img src={logoPic} alt='cv_token' />
                    </div>
                </div>
                <div className="row justify-content-center align-items-center">
                    <div className="col-lg-5 col-md-9 col-sm-10 m-3">
                        <form className="login-form">
                            <div className="mb-3 forgot">
                                <div className='arrow_icon'>
                                    <i class="bi bi-arrow-left-circle"></i>
                                </div>
                                <div className="heading mt-4">
                                    <h4>Forgot Password</h4>
                                    <p>Please enter your registered email to recover your password. </p>
                                </div>
                                <div className="login-email mt-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Email Address</label>
                                    <input type="email" className="input_style form-control" placeholder="Enter Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="mt-5 button">
                                    <button type="submit" className="login-button" onClick={handlePassword}>Continue</button>
                                </div>
                            </div>
                        </form>
                        <div className="now mt-5 d-flex justify-content-center">
                            <p>Already have an account ?<NavLink to='/login' className='link'><span> Login</span></NavLink></p>
                        </div>
                    </div>
                </div>
                <div className="last-div"></div>
            </div>
        </>
    )
}

export default Forgot