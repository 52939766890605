import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import _fetch from '../config/api'
import { base_url } from '../config/config'
import vector from "../assets/icons/Vector.png"
import vector_1 from "../assets/icons/Vector (1).png"
import copy from "../assets/icons/copy_paste.png"
import facebook from "../assets/icons/facebook.png"
import vecto from "../assets/icons/Vector (2).png"
import instagram from "../assets/icons/instagram.png"
import youtube from "../assets/icons/youtube.png"
import frame_1 from "../assets/icons/Frame (1).png"
import frame_2 from "../assets/icons/Frame (2).png"
import frame_3 from "../assets/icons/Frame (3).png"
import frame_4 from "../assets/icons/Frame (4).png"
import frame_5 from "../assets/icons/Frame (5).png"
import frame_6 from "../assets/icons/Frame (6).png"
import car1 from "../assets/icons/car1.png"
import car2 from "../assets/icons/car2.png"
import car3 from "../assets/icons/car3.png"


const Dashboard = () => {
    const [token, setToken] = useState([]);
    const [transaction, setTransaction] = useState([]);
    const [profileName, setProfileName] = useState("");
    const [userId, setUserId] = useState();
    const [sponserId, setSponserId] = useState();
    const [walletAddress, setWalletAddress] = useState();
    const [partners, setPartners] = useState();
    const [founder, setFounder] = useState();
    const [thailand, setThailand] = useState([]);
    const [monsoon, setMonsoon] = useState([]);
    const [tokenPrice, setTokenPrice] = useState([]);
    const [referral, setReferral] = useState([]);
    const [copySuccess, setCopySuccess] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        fetchProfile();
        fetchData();
    }, []);

    const fetchProfile = async () => {
        try {
            const request = await _fetch(`${base_url}api/profile`, 'GET', {}, {});
            // console.log('request------>', );

            setProfileName(request?.user_data?.name)

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    // console.log("name", profileName);


    const fetchData = async () => {
        try {
            const request = await _fetch(`${base_url}api/dashboard`, 'GET', {}, {});
            // console.log("request", request);

            setToken(request?.other_details)
            setTransaction(request?.transaction)
            setUserId(request?.user_data.user_id)
            setWalletAddress(request?.user_data.wallet_address)
            setSponserId(request?.user_data.sponser_id)
            setPartners(request?.board_members.founders);
            setFounder(request?.board_members.eps_partners);
            setThailand(request?.thailand_achievers)
            setMonsoon(request?.monsoon_dhamaka_winners)
            setTokenPrice(request?.user_data.token_price);
            setReferral(request?.referral_link)

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    // console.log("mon", referral);

    // console.log("oartk", partners);
    // console.log("fcgvhbjnk", founder);

    const handleCopyWallet = () => {
        navigator.clipboard.writeText(walletAddress);
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
    };

    const handleCopyReferral = () => {
        navigator.clipboard.writeText(referral);
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
    };

    const handleFounder = () => {
        navigate('/founder_report')
    }
    const handleEps = () => {
        navigate('/eps_report')
    }


    return (
        <>
            <div class="row">
                <div class="col-xl-9 col-md-8 ">
                    <div class="custom mt-1">
                        <div class="card-custom">
                            <div class="d-flex align-items-center">
                                <div className='profile-image'>
                                    <img alt="Profile image of a person" class="profile-img" src="https://placehold.co/60x60" />
                                </div>
                                <div class="profile-info">
                                    <div className='proifle-name'>
                                        <h6 className=" mb-0">
                                            {profileName}
                                        </h6>
                                    </div>
                                    <div className='id'>
                                        <span className='mb-0'>ID-{userId}</span>
                                    </div>
                                    <div className='sponsor-id'>
                                        <span className='mb-0'>Sponsor ID : {sponserId} </span>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-column align-items-end ">
                                <span class="founder-badge mb-2 mt-1">
                                    Founder
                                </span>
                            </div>

                        </div>
                        <div className="d-flex justify-content-between  mb-1">
                            <div className="w">
                                <span class="mb-0">
                                    {walletAddress}
                                </span>
                            </div>
                            <div className='copy-paste_icon' onClick={handleCopyWallet}>
                                <img src={copy} alt='copy_paste' />
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div className="m-2">
                            <div className="heading_name">
                                <h3>Dashboard</h3>
                            </div>
                        </div>
                        <div className="row-container">
                            {token?.map((item, index) => (
                                <div key={index} className="card_tos">
                                    <div className="d-flex align-items-center p-3">
                                        <div className="icons_nill">
                                            <img className="icons_total" src={frame_1} alt="" />
                                        </div>
                                        <div className="content-st">
                                            <div className="content-to">
                                                <span>{item.title}</span>
                                            </div>
                                            <div className="content-val">
                                                <span>{item.value}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="status">
                        <div className="m-3">
                            <div className="heading_name">
                                <h3>Star Club Status</h3>
                            </div>
                        </div>
                        <div class="status_1 mb-3">
                            <div className='text-center'>
                                <img src={vector} alt='' />
                                <div className="mt-2">
                                    <span>$ 2,500</span>
                                </div>
                            </div>
                            <div className='text-center'>
                                <img src={vector} alt='' />
                                <div className="mt-2">
                                    <span>$ 5,000</span>
                                </div>
                            </div>
                            <div className='text-center'>
                                <img src={vector_1} alt='' />
                                <div className="mt-2">
                                    <span>$ 10,000</span>
                                </div>
                            </div>
                            <div className='text-center'>
                                <img src={vector_1} alt='' />
                                <div className="mt-2">
                                    <span>$ 25,000</span>
                                </div>
                            </div>
                            <div className='text-center'>
                                <img src={vector_1} alt='' />
                                <div className="mt-2">
                                    <span>$ 50,000</span>
                                </div>
                            </div>
                            <div className='text-center'>
                                <img src={vector_1} alt='' />
                                <div className="mt-2">
                                    <span>$ 100,000</span>
                                </div>
                            </div>
                            <div className='text-center'>
                                <img src={vector_1} alt='' />
                                <div className="mt-2">
                                    <span>$ 500,000</span>
                                </div>
                            </div>
                            <div className='text-center'>
                                <img src={vector_1} alt='' />
                                <div className="mt-2">
                                    <span>$ 1,000,000</span>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">
                                    Latest Business Update
                                    <hr />
                                </div>
                                <div className="card-body">
                                    <div className="highlight-card">
                                        <div className="d-flex justify-content-between">
                                            <div className="">
                                                <div className="direct-q"><span>Today Members</span></div>
                                                <div className="direct-w"><span>Direct</span></div>
                                                <div className="direct-e"><span>3</span></div>
                                            </div>
                                            <div className="to text-end">
                                                <div className="direct-a"><span>Today Members</span></div>
                                                <div className="direct-s"><span>Direct</span></div>
                                                <div className="direct-d"><span>3</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="highlight-card mt-1">
                                        <div className="d-flex justify-content-between">
                                            <div className="">
                                                <div className="direct-q"><span>Today Members</span></div>
                                                <div className="direct-w"><span>Direct</span></div>
                                                <div className="direct-e"><span>3</span></div>
                                            </div>
                                            <div className="to text-end">
                                                <div className="direct-a"><span></span></div>
                                                <div className="direct-s"><span>Direct</span></div>
                                                <div className="direct-d"><span>3</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="highlight-card mt-1">
                                        <div className="d-flex justify-content-between">
                                            <div className="">
                                                <div className="direct-q"><span>Today Members</span></div>
                                                <div className="direct-w"><span>Direct</span></div>
                                                <div className="direct-e"><span>3</span></div>
                                            </div>
                                            <div className="text-end">
                                                <div className="direct-a"><span></span></div>
                                                <div className="direct-s"><span>Direct</span></div>
                                                <div className="direct-d"><span>3</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">
                                    Leadership Achievements
                                    <hr />
                                </div>
                                <div className="card-body">
                                    <div className="achievement-item">
                                        <div>
                                            <div>Hyundai</div>
                                            <div>$ 70,000</div>
                                            <div className='achieved-badge'>
                                                <span class="achieve mb-2 mt-1">
                                                    Achieved
                                                </span>
                                            </div>
                                        </div>
                                        <img src={car1} alt="Image of BMW X3" />
                                    </div>
                                    <hr />
                                    <div className="achievement-item mt-2">
                                        <div>
                                            <div><span>
                                                Toyota Hycross
                                            </span></div>
                                            <div><span>$ 250,000</span></div>
                                            <div className='achieved-badge'>
                                                <span class="achieve mb-2 mt-1">
                                                    Achieved
                                                </span>
                                            </div>
                                        </div>
                                        <img src={car2} alt="Image of Toyota Hycross" />
                                    </div>
                                    <hr />
                                    <div className="achievement-item mt-2">
                                        <div>
                                            <div><span>BMW X3</span></div>
                                            <div><span>$ 700,000</span></div>
                                            <div className='achieved-badge'>
                                                <span class="achieve mb-2 mt-1">
                                                    Achieved
                                                </span>
                                            </div>
                                        </div>
                                        <img src={car3} alt="Image of BMW X3" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="onew p-3">
                            <div className="heading_name">
                                <h3>Latest Withdraw</h3>
                            </div>
                            <div className="rounded">
                                <div className="table-responsive mt-3">
                                    <table class="table">
                                        <thead className='table-heading'>
                                            <tr>
                                                <th scope="col">Asset</th>
                                                <th scope="col">Transaction Hash</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody className='table-color'>
                                            {transaction && transaction.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.asset}</td>
                                                        <td>{item.transaction_hash}</td>
                                                        <td>{item.date}</td>
                                                        <td>{item.amount}</td>
                                                        <td>{item.payable_amount}</td>
                                                        <td style={{ color: `${item.status === 'APPROVED' ? 'green' : item.status === 'REJECTED' ? 'red' : ''}` }}>{item.status}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> */}
                    </div>

                </div>
                <div class="col-xl-3 col-md-4">
                    <div class="token">
                        <div class="card-body_token">
                            <h6 class="card-title">Token Price</h6>
                            <div class="card-text"><span class="display-7">$ {tokenPrice}</span>
                                {/* <span class="badge-token">+15.5%</span> */}
                            </div>
                            <div className='box-sh'></div>
                        </div>
                    </div>
                    <div class="personal_link-card mt-2">
                        <div class="card-body">
                            <div className='personal-link'>
                                <span>My Personal Link</span>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div className='text-link'>
                                    <p class="card-text">{referral.slice(0, 35)}</p>
                                </div>
                                <div className='copy-paste_icon' onClick={handleCopyReferral}>
                                    <img src={copy} alt='copy_paste' />
                                </div>
                            </div>
                            <div class="icon-wrapper d-flex justify-content-start gap-2">
                                <a href="#" target="_blank"><img className='social-media-icon' src={facebook} alt='' /></a>
                                <a href="#" target="_blank"><img className='social-media-icon' src={youtube} alt='' /></a>
                                <a href="#" target="_blank"><img className='social-media-icon' src={instagram} alt='' /></a>
                                <a href="#" target="_blank"><img className='social-media-icon' src={vecto} alt='' /></a>
                            </div>
                        </div>
                    </div>

                    <div class="card-capping mt-2">
                        <div className="heading-capping">
                            <h5 class="text-center">Overall Capping</h5>
                        </div>
                        <div class="progress mt-4 mb-3">
                            <div class="progress-bar progress-bar-purple"></div>
                            <div class="progress-bar progress-bar-yellow"></div>
                            <div class="progress-bar progress-bar-red"></div>
                            <div class="progress-bar progress-bar-green"></div>
                            <div class="progress-bar progress-bar-grey"></div>
                        </div>
                        <div className="mt-4">
                            <div class="d-flex justify-content-between">
                                <div className='profit'>
                                    <span>Staking Profit</span>
                                </div>
                                <div className='profit'>
                                    <span class="text-end">$1000.00</span>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div className='profit'>
                                    <span>Earned</span>
                                </div>
                                <div className='profit'>
                                    <span class="text-end">$1000.00</span>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div className='profit'>
                                    <span>Withdrawal</span>
                                </div>
                                <div className='profit'>
                                    <span class="text-end">$1000.00</span>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div className='profit'>
                                    <span>Remaining</span>
                                </div>
                                <div className='profit'>
                                    <span class="text-end">$1000.00</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="token-1 mt-2">
                        <div className="members mt-2">
                            <h4>Board Members</h4>
                        </div>
                        <div className="d-flex flex-wrap gap-3">
                            <div class="board d-flex align-content-flex-start gap-1" onClick={handleFounder}>
                                <div className="founder-num">{partners}</div>
                                <div className="founder-nam">Founder's</div>
                            </div>
                            <div class="board d-flex align-content-flex-start gap-1" onClick={handleEps}>
                                <div className="founder-num">{founder}</div>
                                <div className="founder-nam">Founder's</div>
                            </div>
                        </div>

                    </div>

                    <div class="col">
                        <div class="card_achievers mt-2">
                            <div class="card-body">
                                <h5 class="card-title">Thailand Achievers</h5>
                                <hr />
                                <marquee height="250" direction="up" scrollamount="6">
                                    <ul className="list-group list-group-flush mt-2">
                                        {thailand && thailand.map((item, index) => (
                                            <li className="list-group-item" key={index}>
                                                {item.name} <span className="float-end">{item.user_id}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </marquee>
                            </div>
                        </div>

                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <div class="card_achievers">
                                <div class="card-body">
                                    <h5 class="card-title">Monsoon Dhamaka</h5>
                                    <hr></hr>
                                    <marquee height="250" direction="up" scrollamount="6">
                                        <ul className="list-group list-group-flush mt-2">
                                            {monsoon && monsoon.map((item, index) => (
                                                <li className="list-group-item">
                                                    {item.name} <span className="float-end">{item.user_id}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </marquee>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col">
                            <div class="card_achievers">
                                <div class="card-body">
                                    <h5 class="card-title">Leadership Winners</h5>
                                    <hr></hr>
                                    <ul class="list-group list-group-flush mt-2">
                                        <li class="list-group-item">John Doe <span class="float-end">445345</span></li>
                                        <li class="list-group-item">Jane Smith <span class="float-end">453556</span></li>
                                        <li class="list-group-item">Casy Prince <span class="float-end">435456</span></li>
                                        <li class="list-group-item">John Doe <span class="float-end">445345</span></li>
                                        <li class="list-group-item">Jane Smith <span class="float-end">453556</span></li>
                                        <li class="list-group-item">Casy Prince <span class="float-end">435456</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-12">
                    <div className="onew p-3">
                        <div className="heading_name">
                            <h3>Latest Withdraw</h3>
                        </div>
                        <div className="rounded">
                            <div className="table-responsive mt-3">
                                <table class="table">
                                    <thead className='table-heading'>
                                        <tr>
                                            <th scope="col">Asset</th>
                                            <th scope="col">Transaction Hash</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody className='table-color'>
                                        {transaction && transaction.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.asset}</td>
                                                    <td>{item.transaction_hash.slice(0, 35)}</td>
                                                    <td>{item.date}</td>
                                                    <td>{item.amount}</td>
                                                    <td>{item.payable_amount}</td>
                                                    <td>
                                                        <button
                                                            className='button-latest'
                                                            style={{
                                                                backgroundColor: item.status === 'APPROVED' ? 'rgba(65, 211, 62, 1)' : item.status === 'REJECTED' ? '  rgba(227, 62, 56, 1)' : '',
                                                                color: item.status === 'APPROVED' ? 'black' : item.status === 'REJECTED' ? 'white' : ''
                                                            }}>
                                                            {item.status}
                                                        </button>
                                                    </td>                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                                <div className="pagination-custom">
                                    <nav aria-label="Pagination fgh">
                                        <ul className="pagination">
                                            <li className="page-item previous">
                                                <a className="page-link" href="#" aria-label="Previous">
                                                    <span aria-hidden="true">&larr;</span> Previous
                                                </a>
                                            </li>
                                            <div className="page-numbers">
                                                <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                                <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                <li className="page-item"><a className="page-link" href="#">...</a></li>
                                                <li className="page-item"><a className="page-link" href="#">8</a></li>
                                                <li className="page-item"><a className="page-link" href="#">9</a></li>
                                                <li className="page-item"><a className="page-link" href="#">10</a></li>
                                            </div>
                                            <li className="page-item next">
                                                <a className="page-link" href="#" aria-label="Next">
                                                    Next <span aria-hidden="true">&rarr;</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="onew p-3">
                        <div className="heading_name">
                            <h3>Rewards Hub</h3>
                        </div>
                        <div className="rounded">
                            <div className="table-responsive mt-3">
                                <table class="table">
                                    <thead className='table-heading'>
                                        <tr>
                                            <th scope="col">S.No.</th>
                                            <th scope="col">Timeline</th>
                                            <th scope="col">Reward</th>
                                            <th scope="col">Direct Business</th>
                                            <th scope="col">Direct Business</th>
                                            <th scope="col">Claim</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className='table-color'>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>

    )
}

export default Dashboard