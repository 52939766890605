// import React from 'react';
// import Sidebar from '../Sidebar/Sidebar';
// import Header from "../Header/Header";
// const App = ({ children }) => {
//     return (
//         <div className="main_layout">
//             <div className="row">
//                 <Sidebar />
//                 <div className="content">
//                     <Header />
//                     <div className='data-content'>{children}</div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default App;

import React, { useState } from 'react';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';

const App = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const handleSidebarToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="main_layout">
            <div className="row">
                <Sidebar isSidebarOpen={isSidebarOpen} />
                <div className="content">
                    <Header handleSidebarToggle={handleSidebarToggle} isSidebarOpen={isSidebarOpen} />
                    <div className='data-content'>{children}</div>
                </div>
            </div>
        </div>
    );
};

export default App;
