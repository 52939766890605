// 6-9-24 :aaj dynamic tree banay hai , vijay sir ne task diya tha ( done by:madhukar)


import React, { useState } from 'react';
import TreeData from './TreeData';

const generateBinaryTree = (depth) => {
    const createNode = (currentDepth) => {
        if (currentDepth > depth) return null;
        const node = {
            value: `${currentDepth}`,
            left: createNode(currentDepth + 1),
            right: createNode(currentDepth + 1),
        };
        return node;
    };

    return createNode(1);
};

const treeData = generateBinaryTree(20);

const Tree = () => {
    const [zoom, setZoom] = useState(1);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [expandedNodes, setExpandedNodes] = useState(new Map());

    const zoomIn = () => setZoom(prevZoom => prevZoom * 1.2);
    const zoomOut = () => setZoom(prevZoom => Math.max(prevZoom / 1.2, 0.1));
    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
            setIsFullScreen(true);
        } else {
            document.exitFullscreen();
            setIsFullScreen(false);
        }
    };

    const toggleNode = (value, side) => {
        setExpandedNodes(prev => {
            const newExpandedNodes = new Map(prev);
            if (newExpandedNodes.has(value)) {
                newExpandedNodes.delete(value);
            } else {
                newExpandedNodes.set(value, side);
            }
            return newExpandedNodes;
        });
    };

    return (
        <div className={`tree-container ${isFullScreen ? 'fullscreen' : ''}`}>
            <div className="tree" style={{ transform: `scale(${zoom})` }} >
                <ul>
                    <TreeData
                        key={treeData.value}
                        node={treeData}
                        zoom={zoom}
                        onToggle={toggleNode}
                        isOpen={expandedNodes.has(treeData.value)}
                        side="both"
                    />
                </ul>
            </div>
            <div className="controls">
                <button onClick={zoomIn}>
                    <i className="fas fa-plus"></i>
                </button>
                <button onClick={zoomOut}>
                    <i className="fas fa-minus"></i>
                </button>
                <button onClick={toggleFullScreen}>
                    <i className="fas fa-expand"></i>
                </button>
            </div>
        </div>
    );
};

export default Tree;

