import React, { useState } from 'react'

const TreeData = ({ node, zoom, onToggle, isOpen, currentDepth }) => {
    const [expanded, setExpanded] = useState(false);

    const handleToggle = () => {
        setExpanded(!expanded);
        onToggle(node.value, expanded ? 'collapse' : 'expand');
    };

    return (
        <li>
            <a href="#" onClick={handleToggle}>
                <img alt={`Profile picture of ${node.value}`} height="50" src={`https://via.placeholder.com/50?text=${node.value}`} width="50" />
                <br />
                {node.value}
                <br />
              {node.left ? node.left.value : 'null'} {node.right ? node.right.value : 'null'}
            </a>
            {expanded && (node.left || node.right) && (
                <ul>
                    {node.left && (
                        <TreeData
                            key={`left-${node.value}`}
                            node={node.left}
                            zoom={zoom}
                            onToggle={onToggle}
                            isOpen={isOpen}
                            currentDepth={currentDepth + 1}
                        />
                    )}
                    {node.right && (
                        <TreeData
                            key={`right-${node.value}`}
                            node={node.right}
                            zoom={zoom}
                            onToggle={onToggle}
                            isOpen={isOpen}
                            currentDepth={currentDepth + 1}
                        />
                    )}
                </ul>
            )}
        </li>
    );
};

export default TreeData


