import React, { useEffect, useState } from 'react'
import _fetch from '../config/api';
import { base_url } from '../config/config'

const RewardsIncome = () => {

    const [rewardsIncome, setRewardsIncome] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [per_page] = useState(10);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        try {
            const request = await _fetch(`${base_url}api/rewards_income?page=${currentPage}&per_page=${per_page}`, 'GET', {}, {})
            // console.log("rewards-income", request);
            setRewardsIncome(request.data)

            setCurrentPage(parseInt(request.pagination.current_page, 10));
            setTotalPages(parseInt(request.pagination.total_pages, 10));
        } catch (error) {
            console.log("Error fetching data:", error);
        }
    }



    return (
        <>
            <div className="onew p-3">
                <div className="heading">
                    <h5>Rewards Income</h5>
                </div>
                <div className="rounded">
                    <div className="table-responsive mt-3">
                        <table class="table">
                            <thead className='table-heading'>
                                <tr>
                                    <th scope="col">S No.</th>
                                    <th scope="col">Holding</th>
                                    <th scope="col">Income</th>
                                    <th scope="col">Description</th>
                                    <th scope='col'>Date</th>
                                </tr>
                            </thead>
                            <tbody className='table-color'>
                                {rewardsIncome && rewardsIncome.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{(currentPage - 1) * per_page + index + 1}</td>
                                            {/* <td>{index + 1}</td> */}
                                            <td>{item.Holding}</td>
                                            <td>{item.Income}</td>
                                            <td>{item.Description}</td>
                                            <td>{item.Date}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div className="pagination-custom">
                                    <nav aria-label="Pagination fgh">
                                        <ul className="pagination">
                                            <li className="page-item previous">
                                                <a className="page-link" href="#" aria-label="Previous">
                                                    <span aria-hidden="true">&larr;</span> Previous
                                                </a>
                                            </li>
                                            <div className="page-numbers">
                                                <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                                <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                <li className="page-item"><a className="page-link" href="#">...</a></li>
                                                <li className="page-item"><a className="page-link" href="#">8</a></li>
                                                <li className="page-item"><a className="page-link" href="#">9</a></li>
                                                <li className="page-item"><a className="page-link" href="#">10</a></li>
                                            </div>
                                            <li className="page-item next">
                                                <a className="page-link" href="#" aria-label="Next">
                                                    Next <span aria-hidden="true">&rarr;</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RewardsIncome