import React from 'react'
import './assets/Css/Style.css'
import './assets/Css/Modal.css'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Login from './Authentication/Login'
import SignUp from './Authentication/SignUp'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Forgot from './Authentication/Forgot';
import Public from './Routing/Public';
import Private from './Routing/Private';
import Dashboard from './Dashboard/Dashboard';
import Layouts from "./Layouts/Layouts"
import TicketList from './Help/TicketList';
import DailyStakingIncome from './Bonus_Portfolio/DailyStakingIncome';
import DirectIncome from './Bonus_Portfolio/DirectIncome';
import IncomeLedger from './Bonus_Portfolio/IncomeLedger';
import LeadershipBonus from './Bonus_Portfolio/LeadershipBonus';
import MatrixIncome from './Bonus_Portfolio/MatrixIncome';
import RewardsIncome from './Bonus_Portfolio/RewardsIncome';
import StarclubBonus from './Bonus_Portfolio/StarclubBonus';
import StakeTokenHistory from './Staking/StakeTokenHistory';
import StakeToken from './Staking/StakeToken';
import DepositHistory from './FundManagement/DepositHistory';
import WalletLedger from './FundManagement/WalletLedger';
import DirectReferral from './Community /DirectReferral';
import MatrixDownline from './Community /MatrixDownline';
import SelfDownline from './Community /SelfDownline';
import CvtWithdrawals_history from './Withdrawal/CvtWithdrawals_history';
import UsdtWithdrawals_history from './Withdrawal/UsdtWithdrawals_history';
import CreateTicket from './Help/CreateTicket';
import FundTransfer from './Fund_Transfer/FundTransfer';
import CvtWithdrawals from './Withdrawal/CvtWithdrawals';
import UsdtWithdrawals from './Withdrawal/UsdtWithdrawals';
import DepositBep from './FundManagement/DepositBep';
import Account from './Profile/Account';
import ChangePassword from './Profile/ChangePassword';
import ManageStack from './Profile/ManageStack';
import Tree from './Community /Tree';
import Genelogy from './Community /Genelogy';
import TokenDeposit from './FundManagement/TokenDeposit';
import Founder from './Dashboard/Founder';
import Epsfounder from './Dashboard/Epsfounder';

const App = () => {

  return (
    <>
      <Router>
        <Routes>

          {/* // 17-9-24 "CV Token Api lga hai aaj (done by- Madhukar) " */}

          {/* // 18-9-24 "CV Token Api (5) lgaya hu and cv-home page ko responsice issues solve kiya hu aaj (done by- Madhukar) " */}

          {/* Private Routing */}

          <Route element={<Private />}>
            <Route path='/' element={<Layouts><Dashboard /> </Layouts>} />
            <Route path='/eps_report' element={<Layouts><Epsfounder /></Layouts>} />
            <Route path='/founder_report' element={<Layouts><Founder /></Layouts>} />

            <Route path='/deposit_history' element={<Layouts><DepositHistory /></Layouts>} />
            <Route path='/token_deposit' element={<Layouts><TokenDeposit /></Layouts>} />
            <Route path='/wallet_ledger' element={<Layouts><WalletLedger /></Layouts>} />
            <Route path='/ticket_list' element={<Layouts><TicketList /> </Layouts>} />
            <Route path='/daily_staking_income' element={<Layouts><DailyStakingIncome /> </Layouts>} />
            <Route path='/direct_income' element={<Layouts><DirectIncome /> </Layouts>} />
            <Route path='/income_ledger' element={<Layouts><IncomeLedger /> </Layouts>} />
            <Route path='/leadership_bonus' element={<Layouts><LeadershipBonus /> </Layouts>} />
            <Route path='/matrix_income' element={<Layouts><MatrixIncome /> </Layouts>} />
            <Route path='/rewards_income' element={<Layouts><RewardsIncome /> </Layouts>} />
            <Route path='/starclub_bonus' element={<Layouts><StarclubBonus /> </Layouts>} />
            <Route path='/stake_token_history' element={<Layouts><StakeTokenHistory /> </Layouts>} />
            <Route path='/stake_token' element={<Layouts><StakeToken /> </Layouts>} />
            <Route path='/direct_referral' element={<Layouts><DirectReferral /> </Layouts>} />
            <Route path='/matrix_downline' element={<Layouts><MatrixDownline /> </Layouts>} />
            <Route path='/self_downline' element={<Layouts><SelfDownline /> </Layouts>} />
            <Route path='/cvt_withdrawals_history' element={<Layouts><CvtWithdrawals_history /></Layouts>} />
            <Route path='/usdt_withdrawals_history' element={<Layouts><UsdtWithdrawals_history /></Layouts>} />
            <Route path='/create_ticket' element={<Layouts><CreateTicket /></Layouts>} />
            <Route path='/fund_transfer' element={<Layouts><FundTransfer /></Layouts>} />
            <Route path='/cvt_withdrawal' element={<Layouts><CvtWithdrawals /></Layouts>} />
            <Route path='/usdt_withdrawal' element={<Layouts><UsdtWithdrawals /></Layouts>} />
            <Route path='/deposit_bep' element={<Layouts><DepositBep /></Layouts>} />
            <Route path='/stake_tokens' element={<Layouts><StakeToken /></Layouts>} />
            <Route path='/tree_chart' element={<Layouts><Tree /></Layouts>} />
            <Route path='/genelogy' element={<Layouts><Genelogy /></Layouts>} />
            <Route path='/account' element={<Layouts><Account /></Layouts>} />
            <Route path='/change_password' element={<Layouts><ChangePassword /></Layouts>} />
            <Route path='/manage_stack' element={<Layouts><ManageStack /></Layouts>} />

          </Route>

          {/* Public Routing */}
          <Route element={<Public />}>
            <Route path='/login' element={<Login />} />
            <Route path='/signup' element={<SignUp />} />
            <Route path='/forgot' element={<Forgot />} />
          </Route>
        </Routes>

        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </Router>



    </>
  )
}

export default App