import React, { useState } from 'react'
import { base_url } from '../config/config';
import toast from 'react-hot-toast';

const CvtWithdrawals = () => {

    const [amount, setAmount] = useState("");
    const [eth_address, setEth_address] = useState("");

    // console.log("eth",eth_address);


    const handleTicket = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem("token");
        if (!token) {
            console.error("Token not found");
            return;
        }

        // console.log("token", token);

        try {
            const requestData = {
                amount,
                eth_address,
            };

            let response = await fetch(`${base_url}api/cvtamount-withdraw`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": token,
                },
                body: JSON.stringify(requestData),
            })

            const data = await response.json();
            console.log("data", data);

            setAmount("");
            setEth_address("");

            // if (data.status === true) {

            // } else {
            //     toast.error()
            // }


        } catch (error) {
            console.error('Error with email login:', error);
        }
    }

    return (
        <>
            <div className="col-lg-6 col-md-10 col-sm-12">
                <div className="heading p-1 mb-3 mt-1">
                    <h4>Available Balance</h4>
                </div>
                <form className="login-form">
                    <div className="mb-3 transfer">
                        <div className="login-email mt-4">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Amount</label>
                            <input type="number" className="input_style form-control" autoComplete='off' placeholder="Enter Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                        </div>
                        <div className="login-password mt-4">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Wallet Address</label>
                            <input type='text' className="textarea input_style form-control" autoComplete='off' placeholder="Enter Wallet Address" value={eth_address} onChange={(e) => setEth_address(e.target.value)} />
                            <div className="mb-3 button mt-5">
                                <button type="submit" className="submit-button" onClick={handleTicket}>Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default CvtWithdrawals