import React, { useEffect, useState } from 'react'
import { base_url } from '../config/config';
import _fetch from '../config/api';
import { toast } from 'react-toastify';

const StakeTokenHistory = () => {

    const [history, setHistory] = useState([]);

    const fetchHistory = async () => {
        try {
            const request = await _fetch(`${base_url}api/stakehistory`, 'GET', {}, {});
            console.log('request------>', request);
            if (request.status === true) {
                setHistory(request?.data.token_deposit_report)
            } else {
                toast.error(request.message)
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }


    useEffect(() => {
        fetchHistory();
    }, []);

    return (
        <>
            <div className="onew p-3">
                <div className="heading">
                    <h5>Stake Token History</h5>
                </div>
                <div className="rounded">
                    <div className="table-responsive mt-3">

                        <table class="table">
                            <thead className='table-heading'>
                                <tr>
                                    <th scope="col">Stacked Tokens</th>
                                    <th scope="col">Locking Period</th>
                                    <th scope="col">Release Date</th>
                                    <th scope="col">Date</th>
                                </tr>
                            </thead>
                            <tbody className='table-color'>
                                {history && history.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.staked_tokens}</td>
                                            <td>{item.locking_period}</td>
                                            <td>{item.release_date}</td>
                                            <td>{item.date}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StakeTokenHistory