import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { base_url } from '../config/config';

const ManageStack = () => {

    const [rbc_address, setRbc_address] = useState();

    // console.log("rbc", rbc_address);

    const handleWallet = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("Token not found");
            return;
        }

        // console.log("token", token);

        try {
            const requestData = {
                rbc_address,
            };

            let response = await fetch(`${base_url}api/update-address`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": token,
                },
                body: JSON.stringify(requestData),
            })

            const data = await response.json();
            // console.log("data", data);

            setRbc_address("")

        } catch (error) {
            console.error('Error with email login:', error);
        }

    }

    return (
        <>
            <div className="row gap-5">
                <div className="col-lg-3 col-md-4 col-sm-3">
                    <ul className='nav nav-pills  flex-column mt-sm-0'>
                        <li className='nav-item text-white fs-4 my-1 py-2 py-sm-0'>
                            <NavLink to='/account' className='nav-link account-setting text-white fs-5' aria-current='page'>
                                <span className='ms-3'>Manage Profile</span>
                            </NavLink>
                        </li>
                        <li className='nav-item text-white fs-4 my-1 py-2 py-sm-0'>
                            <NavLink to='/change_password' className='nav-link account-setting fs-5' aria-current='page' >
                                <span className='ms-3'>Change Password</span>
                            </NavLink>
                        </li>
                        <li className='nav-item text-white fs-4 my-1 py-2 py-sm-0'>
                            <NavLink to='/manage_stack' className='nav-link account-setting fs-5' aria-current='page' >
                                <span className='ms-3'>Manage Stack Address</span>
                            </NavLink>
                        </li>
                    </ul>

                </div>
                <div className="col-lg-6 col-md-9 col-sm-8">
                    <form className="login-form">
                        <div className="mb-3 transfer">
                            <div className="login-data mt-4">
                                <div className='headlight-data'><span className='headlight-data-span'>Submit only USDT BEP20 wallet address of Trust Wallet / MetaMask / CVTrade Exchange.</span></div>
                            </div>
                            <div className="login-password mt-4">
                                <label htmlFor="exampleFormControlInput1" className="form-label">BEP 20 Address</label>
                                <input type='text' className="textarea input_style form-control" autoComplete='off' placeholder="Enter BEP Address" value={rbc_address} onChange={(e) => setRbc_address(e.target.value)} />
                                <div className="mb-3 button mt-5">
                                    <button type="submit" className="submit-button" onClick={handleWallet}>Update</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ManageStack