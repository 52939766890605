import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Modals = () => {
    const notify = () => {
        document.body.classList.add('blur');
        toast.success('Login Authentication successful!', {
            onClose: () => document.body.classList.remove('blur'),
            position: "top-center",
            className: "custom-toast",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    useEffect(() => {
        notify();
    }, []);

    return (
        <div>
            <ToastContainer />
        </div>
    );
};

export default Modals;
