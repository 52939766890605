// import React, { useState } from 'react';
// import LogoutModal from '../Authentication/LogoutModal';
// import { NavLink, useNavigate } from 'react-router-dom';
// import user_icon from "../assets/icons/user.png"

// const Header = () => {

//     const navigate = useNavigate();
//     const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

//     const logout = () => {
//         localStorage.clear('token');
//         navigate('/login');
//     };
//     return (
//         <>
//             <nav className="col-lg-12 navbar navbar-expand-lg">
//                 <div className="container-fluid">
//                     <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//                         <span className="navbar-toggler-icon" />
//                     </button>
//                     <div className="collapse navbar-collapse" id="navbarSupportedContent">
//                         <form className="d-flex me-auto" role="search">
//                             <div className="search-container">
//                                 <i className="bi bi-search search-icon"></i>
//                                 <input className="form-control input_form_control " type="search" placeholder="Search" aria-label="Search" />
//                             </div>
//                         </form>

//                         <div className='header-user-icon'>
//                             <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
//                                 <li className="nav-item">
//                                     <div className="icons-header">
//                                         <img src={user_icon} alt='user' />
//                                     </div>
//                                 </li>
//                                 <li className="nav-item dropdown">
//                                     <a href='#' className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
//                                         User Name
//                                     </a>
//                                     <ul className="dropdown-menu">
//                                         <li><NavLink to='/account' className="dropdown-item">My Account</NavLink></li>
//                                         <li><a className="dropdown-item" href="#" onClick={() => setIsLogoutModalOpen(true)}>Log Out</a></li>
//                                     </ul>
//                                 </li>
//                             </ul>
//                         </div>
//                     </div>
//                 </div>
//             </nav>

//             <LogoutModal
//                 isOpen={isLogoutModalOpen}
//                 onCancel={() => setIsLogoutModalOpen(false)}
//                 onConfirm={() => {
//                     setIsLogoutModalOpen(false);
//                     logout();
//                 }}
//             />
//         </>
//     );
// };

// export default Header;

import React, { useState } from 'react';
import LogoutModal from '../Authentication/LogoutModal';
import { NavLink, useNavigate } from 'react-router-dom';
import user_icon from "../assets/icons/user.png"

const Header = ({ handleSidebarToggle, isSidebarOpen }) => {
    const navigate = useNavigate();
    // const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

    const logout = () => {
        localStorage.clear('token');
        navigate('/login');
    };

    return (
        <>
            <nav className="col-lg-12 navbar navbar-expand-lg">
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>

                    <button className="sidebar-toggle btn btn-primary d-lg-none" type='button' onClick={handleSidebarToggle}>
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <form className="d-flex me-auto" role="search">
                            <div className="search-container">
                                <i className="bi bi-search search-icon"></i>
                                <input className="form-control input_form_control " type="search" placeholder="Search" aria-label="Search" />
                            </div>
                        </form>

                        <div className='header-user-icon'>
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <div className="icons-header">
                                        <img src={user_icon} alt='user' />
                                    </div>
                                </li>
                                <li className="nav-item dropdown">
                                    <a href='#' className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        User Name
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li><NavLink to='/account' className="dropdown-item">My Account</NavLink></li>
                                        <li><a className="dropdown-item" href="#" onClick={() => logout()}>Log Out</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </nav>

            {/* <LogoutModal
                isOpen={isLogoutModalOpen}
                onCancel={() => setIsLogoutModalOpen(false)}
                onConfirm={() => {
                    setIsLogoutModalOpen(false);
                    logout();
                }}
            /> */}
        </>
    );
};

export default Header;
